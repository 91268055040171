<template>
    <!-- <div v-html="html"></div> -->
    <div>
        <div style="text-align:center; width:100vw; margin-top:20vh;">正在处理、请勿关闭...</div>
        <div class="spinner">
			<div class="bounce1"></div>
			<div class="bounce2"></div>
			<div class="bounce3"></div>
		</div>
    </div>
    

</template>
<!-- <script src="http://code.jquery.com/jquery-3.5.1.min.js"></script> -->
<style>
		.spinner {
		  margin: 25px auto 0;
		  width: 150px;
		  text-align: center;
		}
		 
		.spinner > div {
		  width: 30px;
		  height: 30px;
		  background-color: #67CF22;
		 
		  border-radius: 100%;
		  display: inline-block;
		  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
		  animation: bouncedelay 1.4s infinite ease-in-out;
		  /* Prevent first frame from flickering when animation starts */
		  -webkit-animation-fill-mode: both;
		  animation-fill-mode: both;
		}
		 
		.spinner .bounce1 {
		  -webkit-animation-delay: -0.32s;
		  animation-delay: -0.32s;
		}
		 
		.spinner .bounce2 {
		  -webkit-animation-delay: -0.16s;
		  animation-delay: -0.16s;
		}
		 
		@-webkit-keyframes bouncedelay {
		  0%, 80%, 100% { -webkit-transform: scale(0.0) }
		  40% { -webkit-transform: scale(1.0) }
		}
		 
		@keyframes bouncedelay {
		  0%, 80%, 100% {
			transform: scale(0.0);
			-webkit-transform: scale(0.0);
		  } 40% {
			transform: scale(1.0);
			-webkit-transform: scale(1.0);
		  }
		}
</style>
<script>
import api from "../../api/ztb";
import { Dialog } from 'vant';
import base from '../../api/base';
// import { dir } from "console";
export default {
    // ============================================
    // ====================微信外支付成功后跳转页
    // ============================================
    components: {
        [Dialog.Component.name]: Dialog.Component,
    },
    data() {
        return {
            // html: '<p style="text-align:center;">处理订单，请勿关闭页面...</p>',
            isShow: true,
        }
    },
    created() {
        // setTimeout(,5000);
        this.fetchVideoPay()
    },
    methods: {
        beforeClose(action, done) {
            // console.log("***********ddd***********")
            // console.log(this.$route.query)
            // console.log(this.$route.params)
            done(true) //关闭
        },
        fetchVideoPay() {
            // if (location.href.indexOf("#reloaded") == -1) {
            //     location.href = location.href + "#reloaded";
            //     window.location.reload();
            // }
            var url = window.location.href ;             //获取当前url

            // var dz_url = url.split('#')[0];                //获取#/之前的字符串

            // var cs = dz_url.split('?')[1];                //获取?之后的参数字符串

            // var cs_arr = cs.split('&');                    //参数字符串分割为数组

            // var cs={};

            // for(var i=0;i<cs_arr.length;i++){         //遍历数组，拿到json对象

            // cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]

            // }
            
            
            var cs = url.split('?')[1];
            var cs_arr = cs.split('=');
            // Dialog.alert({
            //     message: "请点击确认\n订单编号：" + cs_arr[1]
            // }).then(() => {
            //     // on close
            // })

            // alert("请点击确认\n订单编号：" + cs_arr[1])
            // var cs={};
            // for(var i=0;i<cs_arr.length;i++){         //遍历数组，拿到json对象
            //     cs[cs_arr[i]['out_trade_no']] = cs_arr[i][1]
            // }
            // alert("FFF" + cs_arr[0])
            // alert("AAA" + cs_arr[1])
            // return

            // let order_code = this.$route.query.out_trade_no


            // let order_code = localStorage.getItem('order_code')
            // alert(cs_arr[1])
            //请求订单更新
            let param = {
                    // wx_order: order_code
                    wx_order: cs_arr[1]
                };
                // if (window.Storage && window.localStorage && window.localStorage instanceof Storage) {
                    
                // } else {
                //     alert('您处于无痕浏览，无法为您保存');
                // }
                setTimeout(function(){
                    api.postWxOrderUpdate(param).then((res) => {
                        if(res.status == 200){
                            // alert(res.data.Result.user_phone)
                            // alert(res.data.Error_code)
                            // this.$router.push({path: 'PayAllResult'});
                            // window.location= base.ztbWebHost + '/payAllResult'
                            // alert("您的订单已生成，请打开App畅学吧！")
                            // if(res.data.Error_code == 0){
                                //http://www.evoka.com.cn/jnj_download_new.html?orderStatus=1&user_phone=13524181542&order_code99999999999999&shop_price=98&payment_type=1&is_payment=1
                            window.location.href=base.ztbWebHost + "?orderStatus=" + res.data.Error_code + "&user_phone=" + res.data.Result.user_phone + "&order_code=" + res.data.Result.order_code + "&shop_price=" + res.data.Result.shop_price + "&payment_type=" + res.data.Result.payment_type + "&is_payment=" + res.data.Result.is_payment;
                        }else{
                            console.log("支付成功，但平台订单，用户授权更新失败！")
                            }
                    })
                },8000)
        },
    
    },
    // mounted() {
    //     this.fetchVideoPay()
    // }
}
</script>